/******************************************
 *  Author : Suraj Sharma
 *  Created On : Mon Jan 04 2021
 *  File : NavigationConstants.jsx
 *******************************************/

export const preAuthPaths = {
    FORGOTPASSWORD: '/forgotPassword',
    SIGNUP: '/signup',
    LOGIN: '/login',
    MAPUPDATE: '/edit-company-forest',
    HOME: '/',
    FOREST: '/forest',
    RESETPASSWORD: '/resetPassword',
    VERIFIACCOUNT: '/verification',
    PROFILE: '/profile',
    MESSAGE: '/message',
    AUDITORAPP: '/auditor-app'
    // DYNAMIC: '/[dynamicPage]'
}

export const postAuthPaths = {
    HOME: '/',
    HOME_ALIAS: '/home',
    PORTAL: '/portal',
    HUB: '/hub',
    FOREST: '/forest',
    VLOG: '/vlog',
    DINERS: '/diners',
    BIO: '/bio',
    PROFILE: '/profile',
    ABOUT: '/about',
    MAPUPDATE: '/edit-company-forest',
    AUDITORAPP: '/auditor-app'
    // DYNAMIC: '/[dynamicPage]'
}

export const allPaths = [
    '/forgotPassword',
    '/signup',
    '/login',
    '/',
    '/forest',
    '/resetPassword',
    '/verification',
    '/profile',
    '/message',
    '/home',
    '/portal',
    '/hub',
    '/vlog',
    '/diners',
    '/bio',
    '/about',
    '/edit-company-forest',
    '/auditor-app'
];
